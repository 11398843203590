import { Injectable, RendererFactory2 } from '@angular/core';
import { MessageDetails } from 'src/app/models/mail-details.interface';

// Used only in this file
interface ExtLoaderData {
  id: string;
  transaction?: Record<string, any>;
  showReceipt: boolean;
  initFnLoaded?: true;
  needLogin?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ExtensionEventService {
  private mailDetails: MessageDetails;

  constructor() {
    this.setFyleButtonProperties();
  }

  setMessageDetails(mailDetails: MessageDetails) {
    this.mailDetails = mailDetails;
  }

  getMessageDetails(): MessageDetails {
    return this.mailDetails;
  }

  postMessageToExtension(data: Record<string, any>) {
    // extension's parent will always have the domain https://mail.google.com
    const originFrame = 'https://mail.google.com';
    parent.window.postMessage(JSON.stringify(data), originFrame);
  }

  enableFyleButton() {
    const data: ExtLoaderData = {
      id: this.mailDetails.id,
      showReceipt: false,
      initFnLoaded: true,
    };
    this.postMessageToExtension(data);
  }

  setFyleButtonProperties() {
    const customCSS = {
      borderRadius: '4px',
      padding: '11px 14px',
    };

    const setPillStates = {
      setProp: true,
      fyleIcon: {
        marginRight: '6px',
        width: '20px',
        height: '20px',
      },
      states: {
        NOT_FYLED: {
          text: 'Add Expense',
          css: {
            ...customCSS,
            backgroundColor: '#2c304e',
          },
        },
        DRAFT: {
          text: 'draft',
          css: {
            ...customCSS,
            backgroundColor: '#34495e',
          },
        },
        COMPLETE: {
          text: 'complete',
          css: {
            ...customCSS,
            backgroundColor: '#8e46ff',
          },
        },
        APPROVER_PENDING: {
          text: 'reported',
          css: {
            ...customCSS,
            backgroundColor: '#556ef2',
          },
        },
        APPROVED: {
          text: 'approved',
          css: {
            ...customCSS,
            backgroundColor: '#077e8c',
          },
        },
        PAYMENT_PENDING: {
          text: 'payment pending',
          css: {
            ...customCSS,
            backgroundColor: '#077e8c',
          },
        },
        PAYMENT_PROCESSING: {
          text: 'payment processing',
          css: {
            ...customCSS,
            backgroundColor: '#077e8c',
          },
        },
        PAID: {
          text: 'paid',
          css: {
            ...customCSS,
            backgroundColor: '#55b07d',
          },
        },
      },
    };
    this.postMessageToExtension(setPillStates);
  }

  closeIframe() {
    const data = {
      closeIframe: true,
    };
    this.postMessageToExtension(data);
  }

  loadExpense(expense: Record<string, any>) {
    const data: ExtLoaderData = {
      id: this.mailDetails.id,
      transaction: expense,
      needLogin: false,
      showReceipt: false,
    };

    this.postMessageToExtension(data);
  }
}
