import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private localStorageService: LocalStorageService) {}

  getAccessToken(): string {
    return this.localStorageService.get('X-AUTH-TOKEN');
  }

  getRefreshToken(): string {
    return this.localStorageService.get('X-REFRESH-TOKEN');
  }

  setAccessToken(accessToken: string) {
    this.localStorageService.set('X-AUTH-TOKEN', accessToken);
  }

  setRefreshToken(refreshToken: string) {
    this.localStorageService.set('X-REFRESH-TOKEN', refreshToken);
  }

  setClusterDomain(clusterDomain: string) {
    this.localStorageService.set('CLUSTER-DOMAIN', clusterDomain);
  }

  getClusterDomain(): string {
    return this.localStorageService.get('CLUSTER-DOMAIN');
  }

  resetAccessToken() {
    this.localStorageService.remove('X-AUTH-TOKEN');
  }

  resetClusterDomain() {
    this.localStorageService.remove('CLUSTER-DOMAIN');
  }
}
