import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestOptions } from '../models/http-client.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiEndpoint: string;

  constructor(
    @Inject('ROOT_URL') private rootUrl: BehaviorSubject<string>,
    private httpClient: HttpClient,
    private loggerService: NGXLogger
  ) {
    this.rootUrl.subscribe((apiEndpoint) => {
      this.apiEndpoint = `${apiEndpoint}/api`;
    });
  }

  get<T>(url: string, config?: HttpRequestOptions): Observable<T> {
    const apiPath: string = `${this.apiEndpoint}${url}`;
    return this.httpClient.get<T>(apiPath, config);
  }

  post<T>(url: string, body: Record<string, any> = {}, config?: HttpRequestOptions): Observable<T> {
    const apiPath: string = `${this.apiEndpoint}${url}`;
    return this.httpClient.post<T>(apiPath, body, config);
  }

  delete<T>(url: string, config?: HttpRequestOptions): Observable<T> {
    const apiPath: string = `${this.apiEndpoint}${url}`;
    return this.httpClient.delete<T>(apiPath, config);
  }
}
