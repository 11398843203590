import { Component, OnInit } from '@angular/core';
import { ExtensionEventService } from '@core/services/extension-event.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private extensionEventService: ExtensionEventService) {}

  ngOnInit() {}

  closeIframe() {
    this.extensionEventService.closeIframe();
  }
}
