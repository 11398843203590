import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { CoreModule } from './core/core.module';
import { HttpConfigInterceptor } from './core/interceptors/http-interceptor';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.TRACE,
      // passing empty string to not to display timeStamp(UTC) while logging as it doesn't look good.
      timestampFormat: ' ',
      disableFileDetails: true,
    }),
    NgxLocalStorageModule.forRoot({
      prefix: 'fyle',
      delimiter: '.',
    }),
  ],
  providers: [
    // required by NgxLogger if passing timeStamp.
    DatePipe,
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS,
    },
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: 'ROOT_URL',
      useValue: new BehaviorSubject(environment.ROOT_URL),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
