<ng-container *ngIf="!viewBox && !attribute">
  <svg [attr.class]="classes"
       [attr.width]="width"
       [attr.height]="height || width">
    <title *ngIf="title && src" [attr.id]="src.split('#')[1] + '-title'">{{title}}</title>
    <use [attr.xlink:href]="src"></use>
  </svg>
</ng-container>

<ng-container *ngIf="!viewBox && attribute">
  <svg [attr.class]="classes"
       [attr.width]="width"
       [attr.height]="height || width"
       svgIconSpriteAttr [attribute]="attribute">
    <title *ngIf="title && src" [attr.id]="src.split('#')[1] + '-title'">{{title}}</title>
    <use [attr.xlink:href]="src"></use>
  </svg>
</ng-container>

<ng-container *ngIf="viewBox && !attribute">
  <svg [attr.class]="classes"
       [attr.width]="width"
       [attr.height]="height || width"
       [attr.viewBox]="viewBox"
       [attr.preserveAspectRatio]="preserveAspectRatio">
    <title *ngIf="title && src" [attr.id]="src.split('#')[1] + '-title'">{{title}}</title>
    <use [attr.xlink:href]="src"></use>
  </svg>
</ng-container>

<ng-container *ngIf="viewBox && attribute">
  <svg [attr.class]="classes"
       [attr.width]="width"
       [attr.height]="height || width"
       [attr.viewBox]="viewBox"
       [attr.preserveAspectRatio]="preserveAspectRatio"
       svgIconSpriteAttr [attribute]="attribute">
    <title *ngIf="title && src" [attr.id]="src.split('#')[1] + '-title'">{{title}}</title>
    <use [attr.xlink:href]="src"></use>
  </svg>
</ng-container>
