import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { ExtendedAccount } from '../models/account.model';
import { AuthResponse, AuthPayload } from '../models/auth.model';
import { ExtendedOrgUser } from '../models/user.model';
import { ApiService } from './api.service';
import { DataTransformService } from './data-transform.service';
import { TokenService } from './token.service';
import { ResendEmailVerificationPayload } from '../models/resent-email-verification-payload.model';
import { ResendEmailVerificationResponse } from '../models/resend-email-verification-response.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
    private dataTransform: DataTransformService,
    private localStorageService: LocalStorageService
  ) {}

  isLoggedIn(): boolean {
    return (
      !!this.tokenService.getAccessToken() &&
      !!this.tokenService.getRefreshToken() &&
      !!this.tokenService.getClusterDomain()
    );
  }

  refreshEou(): Observable<ExtendedAccount[]> {
    return this.apiService.get<ExtendedOrgUser>('/eous/current').pipe(
      map((data) => {
        const extendedOrgUser: ExtendedAccount[] = this.dataTransform.unflatten(data);
        this.localStorageService.set('user', extendedOrgUser);
        return extendedOrgUser;
      })
    );
  }

  newRefreshToken(refreshToken: string): Observable<ExtendedAccount[]> {
    this.localStorageService.remove('user');
    this.localStorageService.remove('role');
    this.tokenService.resetAccessToken();
    this.tokenService.setRefreshToken(refreshToken);

    const params: AuthPayload = {
      refresh_token: refreshToken,
    };

    return this.apiService.post<AuthResponse>('/auth/access_token', params).pipe(
      switchMap((res) => {
        this.tokenService.setAccessToken(res.access_token);
        return this.refreshEou();
      })
    );
  }

  refreshAccessToken(refreshToken: string): Observable<void> {
    return this.apiService.post<AuthResponse>('/auth/access_token', { refresh_token: refreshToken }).pipe(
      map((res) => {
        this.tokenService.setAccessToken(res.access_token);
        return;
      })
    );
  }

  getEou(): ExtendedOrgUser {
    return this.localStorageService.get('user');
  }

  getRoles(): string[] {
    const currentUser = this.getEou();
    return currentUser.ou.roles;
  }

  logout(): Observable<Record<string, string>> {
    return this.apiService.post<Record<string, string>>('/auth/logout').pipe(
      map((res) => {
        this.clearCache();
        return res;
      })
    );
  }

  clearCache() {
    this.localStorageService.clear();
    globalCacheBusterNotifier.next();
  }
}
